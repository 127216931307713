import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import { arc as d3Arc } from "d3-shape";

const RadialBarChart = (props) => {
  const { data, metaConfig,width } = props;
  const chartRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const { colors, valueKey = 'value' } = metaConfig;
      const radius = (Math.min(height, width) / 2) * 0.8;
      const barWidth = radius / 5,
        labelWidth = radius / 3,
        labelHeight = radius / 4;
      const svg = d3Select(chartRef.current)
        .append("svg")
        .attr("height", height)
        .attr("width", width);
      const svgG = svg
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .selectAll("slices")
        .data(data);

      svgG
        .join("path")
        .attr("d", (d, i) => {
          const arc = d3Arc()
            .outerRadius(radius - 2 * i * barWidth)
            .innerRadius(radius - 2 * i * barWidth - barWidth);
          return arc({ startAngle: 0, endAngle: d[valueKey] * 2 * Math.PI });
        })
        .attr("fill", (_, i) => colors[i]);

      d3Select(chartRef.current)
        .append("div")
        .attr("id", "labels-container")
        .selectAll(".label")
        .data(data);
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
      d3Select(chartRef.current).select("div").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, destroyChart, rebuildChart,width]);

  useEffect(rebuildChart, [data, metaConfig, rebuildChart,width]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    ></div>
  );
};

export default RadialBarChart;
