import React, { useState, useEffect } from "react"
import { Button } from '@mui/material'
import FilterDropdowns from "components/filters/generic-filter/filter-dropdown"
import { useSelector } from "react-redux";

const ComparisonFilter = ({
	onChange = null,
	runFilters = (e)=>{},
	asyncEmptied = async () => {}
}) => {

	const { http } = global.services
	const [loaded, setLoaded] = useState(false);
	const [ selected, setSelected ] = useState([])
	const user = useSelector(state => state.user.user)
	const {selectedProgramId, clearcache} = useSelector(({app})=> app)

	const [ state, setState ] = useState({
		selectedFilter: {},
		selectedFilterOptions: [],
		filters: [
			{
				name: 'Category',
				placeholder: 'Category',
				value: null,
				options: [ // This will be just default lists
					{ label: 'All Winners', value: 'AllYes', selected: false },
					{ label: 'All Non-Winners', value:'AllNo', selected: false },
					{ label: 'Large Winners', value: 'LargeYes', selected: false },
					{ label: 'Large Non-Winners', value: 'LargeNo', selected: false },
					{ label: 'Medium Winners', value: 'MediumYes', selected: false },
					{ label: 'Medium Non-Winners', value: 'MediumNo', selected: false },
					{ label: 'Small Winners', value: 'SmallYes', selected: false },
					{ label: 'Small Non-Winners', value: 'SmallNo', selected: false },
					{ label: 'Super Winners', value: 'SuperYes', selected: false },
					{ label: 'Super Non-Winners', value: 'SuperNo', selected: false },
				]
			}
		]
		//filters: []
	})

	useEffect(() => {
		loadFilters()
		setLoaded(true);
	}, [])

	useEffect(()=>{
		if (onChange) {
			//Setup changes.
			let { data } = selected[0] || {};
			// console.log('output selected', output, selected);
			onChange(data?.value)
		}
		if(selected.length == 0 && loaded && asyncEmptied) {
			asyncEmptied();
		}
	},[selected])
	//Created once.

	const loadFilters = async () => {
		let query = {}
		const { organizationProgram } = (user.userData) ? user.userData : user.user;

		if(organizationProgram) {
			query.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id
		}
		if(clearcache) query.clearCache = clearcache

		try {
			const { data } = await http.get('client/getWinnersList', {...query})
			setState({ ...state, filters: [
				{
					name: 'Category',
					placeholder: 'Category',
					value: null,
					options: data.map(i => ({
						...i,
						label: i.title,
						value: i.key,
						selected: false
					}))
				}
			]})

		} catch(err){
			console.log(err)
			return;
		}
	}

	const handleFilterChange = (data, index, willSelect = true) => {
		const { filters } = state
		let { selectedFilterOptions } = state
		const filterOptionCode = `${index}-${data.index}`
		for (let filterCount = 0; filterCount < filters.length; filterCount++) {
			for (let optionCount = 0; optionCount < filters[filterCount].options.length; optionCount++) {
				filters[filterCount].options[optionCount].selected = false
			}
		}
		filters[index].options[data.index].selected = willSelect
		filters[index].selectedFilter = data.data
		if (willSelect) {
			if (!selectedFilterOptions.includes(filterOptionCode)) {
				selectedFilterOptions = [ filterOptionCode ]
				setSelected([{ ...data, index: filterOptionCode }])
			}
		} else {
			const selectedDataIndex = filterOptionCode.indexOf(filterOptionCode)
			selectedFilterOptions.splice(selectedDataIndex, 1)
			setSelected( selected.filter(i => i.index != filterOptionCode) )
			filters[index].selectedFilter = {}
		}
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: selectedFilterOptions }))
	}

	const handleClearFilter = () => {
		const { filters } = state
		for (let filterCount = 0; filterCount < filters.length; filterCount++) {
			for (let optionCount = 0; optionCount < filters[filterCount].options.length; optionCount++) {
				filters[filterCount].options[optionCount].selected = false
			}
		}
		setSelected([])
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: [] }))
	}

	return (
		<div className="mb-10">
			<div className='flex flex-row items-center flex-wrap mt-5 ml-2'>
				<div className='flex flex-row items-center space-x-1 mr-5 mb-5'>
					<i className='bx bx-filter-alt text-md'></i>
					<span className='text-sm'>Compare my results with:</span>
				</div>

				{
					state.filters.map((filter, index) => (
						<div key={`filter-dropdown-${index}`} className='mr-5 mb-5'>
							<FilterDropdowns
								key={`filter-dropdown-${index}`}
								name={filter.name}
								value={ filter.value?.label }
								questionId={filter.QuestionId}
								options={filter.options}
								placeholder={filter?.selectedFilter?.label || 'Select Item'}
								onChange={ (data) => {
									handleFilterChange(data, index, !filter.value?.label)
								} }
							/>
						</div>
					))
				}
			</div>

			<div className='flex flex-row flex-wrap'>
				{
					state.filters.map((filter, filterIndex) => (
						<div key={`filter-selected-${filterIndex}`} className='flex'>
							{
								filter.options.map((option, optionIndex) => (
									<React.Fragment key={`filter-${filterIndex}-${optionIndex}`}>
										{
											option.selected && (
												<div key={`option-${filterIndex}-${optionIndex}`} className='mr-5 mb-5 py-2 px-5 bg-gray-0 text-sm text-white flex flex-row items-center space-x-2'>
													<span>
														{ option.label }
													</span>
													<i className='bx bx-x text-lg cursor-pointer' onClick={() => handleFilterChange({ data: option, index: optionIndex }, filterIndex, false)}></i>
												</div>
											)
										}
									</React.Fragment>
								))
							}
						</div>
					))
				}
			</div>

			{
				state.selectedFilterOptions.length > 0 && (
					<div className=''>
						<Button variant="contained" color="error" className="px-14 rounded-none py-2" onClick={runFilters}>Apply</Button>
					</div>
				)
			}
		</div>
	)

}

export default ComparisonFilter
